import React from 'react';
import styled from 'styled-components';

import { useLeetVideo } from '../hooks/useLeetVideo';
import config from '../config';

const VidSidePattern = styled.div`
  &:after {
    position: absolute;
    color: #fff;
    opacity: 1;
    padding: 5px 10px;
    top: 50%;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -2px;
    background: #0c0c91;
    transform: translate3d(0, -50%, 0);
    transition: opacity 0.5s 3.5s ease-in-out, transform 0.5s 3.5s ease-in-out;
    z-index: 5;
    content: '13';
    ${({ shouldDisplay }) => (shouldDisplay ? '' : 'opacity: 0;')}
  }

  &.right:after {
    content: '37';
    right: 0;
    border-left: 4px solid #05e273;
  }
  &:not(.right):after {
    left: 0;
    border-right: 4px solid #05e273;
  }
`;

const VidPattern = styled.div`
  &:after {
    width: 100%;
    left: 0;
    position: absolute;
    color: rgb(0, 43, 85);
    opacity: 0.8;
    padding: 4px;
    white-space: nowrap;
    font-size: 14px;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s 1s ease-in-out, transform 0.5s 1s ease-in-out;
    content: "13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock -- 13:37 o'clock";
    ${({ shouldDisplay }) => (shouldDisplay ? '' : 'opacity: 0;')}
  }
  &.bottom:after {
    bottom: 0;
    ${({ shouldDisplay }) => (shouldDisplay ? '' : 'transform: translate3d(120%,0,0);')}
  }
  &:not(.bottom):after {
    top: 0;
    ${({ shouldDisplay }) => (shouldDisplay ? '' : 'transform: translate3d(-120%,0,0);')}
  }
`;

const CentralVideo = styled.video`
  height: calc(100% - 50px);
  width: calc(100% - 50px);
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  box-sizing: border-box;
  border: 2px solid rgb(0, 43, 85);
  border-top-width: 5px;
  background: rgb(0, 43, 85);
  object-fit: fill;
  transition: opacity 1.5s ease-in-out;
  ${({ shouldDisplay }) => (shouldDisplay ? null : 'display: none')}
`;

const VideoTitleHolder = styled.div`
  height: calc(100% - 50px);
  width: calc(100% - 50px);
  left: 50%;
  top: 50%;
  position: absolute;
  padding: 50px;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0);
  box-sizing: border-box;
  border-top-width: 5px;
  background: rgb(0, 43, 85);
  transition: opacity 0.25s ease-in-out;
  text-align: center;
  color: #05e273;
  border: 2px solid #031c34;
  font-size: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  &.show-title {
    animation: hideVideoTitle 0.45s 10s;
    animation-fill-mode: forwards;
  }

  &:not(.show-title) {
    opacity: 0;
  }
`;

const VideoTitleSmallHolder = styled.div`
  height: calc(100% - 50px);
  width: calc(100% - 50px);
  left: 50%;
  top: 50%;
  position: absolute;
  padding: 10px 0;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0);
  box-sizing: border-box;
  border-top-width: 5px;
  transition: opacity 0.25s ease-in-out;
  text-align: center;
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  pointer-events: none;

  > div {
    background: rgb(0, 43, 85);
    padding: 2px 10px 2px 15px;
    position: relative;
    &:before {
      content: "It's 13:37 o'clock";
      left: 0;
      top: -23px;
      font-size: 15px;
      background: #05e273;
      padding: 2px 12px 2px 15px;
      display: block;
      color: rgb(0, 43, 85);
      position: absolute;
    }
  }

  &:not(.show-title) {
    opacity: 0;
  }
`;

function VideoTitleBig(props) {
  return (
    <VideoTitleHolder className={props.shouldDisplay ? 'show-title' : ''}>
      <div>{props.videoName}</div>
    </VideoTitleHolder>
  );
}

function VideoTitleSmall(props) {
  return (
    <VideoTitleSmallHolder className={props.shouldDisplay ? 'show-title' : ''}>
      <div>{props.videoName}</div>
    </VideoTitleSmallHolder>
  );
}

function LeetOClockVideo({ setIsLeetVideoPlaying }) {
  const videoRef = React.useRef(null);
  const { video, clearVideo } = useLeetVideo();
  const [playing, setPlaying] = React.useState(true);
  const [vidName, setVidName] = React.useState('');

  // First effects triggers video update and starts playing when the time is right
  React.useEffect(() => {
    const startPlaying = async () => {
      if (videoRef.current.src === video.playback) {
        return;
      }
      videoRef.current.src = `${config.s3Endpoint}/videos/${video.id}.mp4`;
      await videoRef.current.load();
      videoRef.current.play().catch(console.error);
      setVidName(video.title);
      setPlaying(true);
      setIsLeetVideoPlaying(true);
    };

    startPlaying();
  }, [videoRef, video]);

  React.useEffect(() => {
    if (videoRef.current) {
      const handler = () => {
        setPlaying(false);
        setIsLeetVideoPlaying(false);
        clearVideo();
      };
      videoRef.current.addEventListener('ended', handler);
      return () => videoRef.current.removeEventListener('ended', handler);
    }
  }, [videoRef]);
  // Second one waits until end and stops the video
  // TODO: also play / pause background video?
  // TODO: background video title shows too brightly? hide / dim / move to lower z-index

  return (
    <>
      <VidPattern shouldDisplay={playing} />
      <VideoTitleSmall videoName={vidName} shouldDisplay={playing} />
      <CentralVideo ref={videoRef} shouldDisplay={playing} />
      <VideoTitleBig videoName={vidName} shouldDisplay={playing} />
      <VidPattern className="bottom" shouldDisplay={playing} />
      <VidSidePattern className="right" shouldDisplay={playing} />
      <VidSidePattern shouldDisplay={playing} />
    </>
  );
}
function LeetOClockVideoWrapper(props) {
  const { isVideoPlaying } = useLeetVideo();
  return isVideoPlaying ? <LeetOClockVideo {...props} /> : null;
}
export default LeetOClockVideoWrapper;
