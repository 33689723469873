import React from 'react';
import styled from 'styled-components';

const ChatBubble = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.01em;
  color: white;

  .name {
    position: absolute;
    right: 5px;
    bottom: -15px;

    font-size: 10px;
    line-height: 12px;
  }

  .container {
    background: #05e273;
    padding: 8px 12px;
    border-radius: 12px;
    max-width: 200px;

    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
  }

  .content {
    font-size: 13px;
    line-height: 15px;
  }

  .icon {
    height: 25px;
    width: 25px;
    margin-right: 8px;
    object-fit: contain;

    border-radius: 50%;
  }
`;

const Svg = styled.svg`
  height: 600px;
  width: 960px;
  position: absolute;
  top: -60px;
`;

const animationPath = 'M 750 650 L 750 -600';

export default ({ picture, message, name }) => {
  const [showImage, setShowImage] = React.useState(true);

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xlinkHref="http://www.w3.org/1999/xlink"
      viewBox="0 0 960 600"
    >
      <path fill="none" d={animationPath} id="animationPath" />
      <g>
        <foreignObject x="0" y="0" width="200" height="500">
          <ChatBubble>
            {showImage && <img className="icon" src={picture} alt="" onError={() => setShowImage(null)} />}
            <div className="container">
              <div className="content">{message}</div>
              <div className="name">{name}</div>
            </div>
          </ChatBubble>
        </foreignObject>
        <animateMotion dur="30s" repeatCount="1">
          <mpath xlinkHref="#animationPath" />
        </animateMotion>
      </g>
    </Svg>
  );
};
