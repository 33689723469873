import { initShaderProgram } from './shaders.js';
import { initBuffers } from './buffers.js';
import { createRenderer } from './renderLoop.js';
import { loadTexture } from './texture.js';

let renderer;

const produceRenderer = video => {
  const canvas = document.querySelector('#glCanvas');
  const gl = canvas.getContext('webgl');
  if (gl === null) {
    // something went wrong
    console.log('Issues setting up WebGL');
    return;
  }
  gl.clearColor(0.0, 0.0, 0.0, 1.0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  const shaderProgram = initShaderProgram(gl);

  const programInfo = {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, 'aVertexPosition'),
      textureCoord: gl.getAttribLocation(shaderProgram, 'aTextureCoord'),
    },
    uniformLocations: {
      projectionMatrix: gl.getUniformLocation(shaderProgram, 'uProjectionMatrix'),
      modelViewMatrix: gl.getUniformLocation(shaderProgram, 'uModelViewMatrix'),
      uSampler: gl.getUniformLocation(shaderProgram, 'uSampler'),
      iChannel0: 0,
      iResolution: [1, 1, 0],
      direction: [1, 1],
    },
  };
  const texture = loadTexture(gl);

  const buffers = initBuffers(gl);
  renderer = createRenderer(gl, programInfo, buffers, texture, video);
};

export const destroyRenderer = () => {
  if (renderer) {
    renderer.stop();
    renderer.destroy();
  }
  renderer = null;
};

export const start = video => {
  if (!renderer) {
    produceRenderer(video);
  }
  renderer.start();
};

export const stop = () => {
  if (renderer) {
    renderer.stop();
  }
};
