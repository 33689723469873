import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Button from './common/Button';
import { reloadApp, switchEnvironment } from '../actions';
import config, { environments } from '../config';
import posed from 'react-pose';
import { useDeviceShuffle } from '../hooks/useDeviceShuffle';
import { useAuthentication } from '../hooks/useAuthentication';

const Container = styled(
  posed.div({
    open: { top: 0 },
    closed: { top: '-200%' },
  })
)`
  margin: 15vh auto;
  left: 0;
  position: absolute;
  width: 80vw;
  margin: 15vh 10vw;
  padding: 1.5% 2%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: rgba(0, 43, 85, 0.93);
  font-size: 10px;
  transition: opacity 0.25s ease-in-out;
  will-change: opacity;
  color: white;
  box-sizing: border-box;
`;

const DeviceCode = styled.div`
  font-size: 13px;
  & > span {
    color: #05e273;
    background-color: #0c0c91;
    padding: 0 8px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: auto;
`;

function videoStats(track) {
  if (!track) return '-';
  return `${track.title} ${track.artist ? ` - ${track.artist}` : ''} (YouTube ID: ${track.id})`;
}

const mapDispatchToProps = dispatch => ({
  reloadApp: () => {
    dispatch(reloadApp());
  },
  switchEnvironment: env => {
    dispatch(switchEnvironment(env));
  },
});

function InfoPanel(props) {
  const { currentTrack } = useDeviceShuffle();
  const {
    logoutDevice,
    state: {
      authentication: {
        device: { name },
      },
    },
  } = useAuthentication();

  return (
    <Container pose={props.isOpened ? 'open' : 'closed'}>
      <DeviceCode>
        _device <span>{name}</span>
      </DeviceCode>
      <DeviceCode>
        _version <span>{process.env.REACT_APP_VERSION || 'dev'}</span>
      </DeviceCode>
      <DeviceCode>
        _api stage <span>{config.stage}</span>
      </DeviceCode>
      {process.env.REACT_APP_COMMIT && (
        <DeviceCode>
          _commit <span>{process.env.REACT_APP_COMMIT}</span>
        </DeviceCode>
      )}
      <DeviceCode>
        _current_track <span>{videoStats(currentTrack)}</span>
      </DeviceCode>
      <Actions>
        <Button small onClick={props.reloadApp}>
          Reload
        </Button>
        <Button small onClick={logoutDevice}>
          Logout
        </Button>
        {environments.map(
          env =>
            env !== config.stage && (
              <Button small key={env} onClick={() => props.switchEnvironment(env)}>
                {env} API
              </Button>
            )
        )}
      </Actions>
    </Container>
  );
}

export default connect(null, mapDispatchToProps)(InfoPanel);
