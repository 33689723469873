import React from 'react';

import { messageActions, useMqttMessages } from './useMqttMessages';

export const useChats = () => {
  const [chats, setChats] = React.useState([]);
  const { subscribeToMqttMessages } = useMqttMessages();

  React.useEffect(() => {
    let timeoutId;
    const queue = [];
    const nextElement = () => {
      if (queue.length) {
        const chat = queue.shift();

        setChats(chats => [...chats, chat]);
        timeoutId = setTimeout(nextElement, 5 * 1000);
      } else {
        timeoutId = setTimeout(() => {
          setChats([]);
          timeoutId = null;
        }, 20 * 1000);
      }
    };
    const addToQueue = chat => {
      if (timeoutId) {
        queue.push(chat);
      } else {
        setChats(chats => [...chats, chat]);
        timeoutId = setTimeout(nextElement, 5 * 1000);
      }
    };

    const unsubDeviceUpdate = subscribeToMqttMessages(messageActions.ChatCreated, addToQueue);

    return () => {
      unsubDeviceUpdate();

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return chats;
};
