import React from 'react';
import styled from 'styled-components';
import './countdown.css';

function NumEl(props) {
	const {type, num = 10, current = 0} = props;
	const previous = (current + 1) % 10;

	return (
		<ul className={type}>
			{new Array(num).fill(null).map((_, i) => (
				<li key={i} className={i === current ? 'flip-clock-active' : previous === i ? 'flip-clock-before' : null}>
					<a href="/" className="">
						<div className="up">
							<div className="shadow"/>
							<div className="inn">{i}</div>
						</div>
						<div className="down">
							<div className="shadow"/>
							<div className="inn">{i}</div>
						</div>
					</a>
				</li>
			))}
		</ul>
	);
}

const produceDigits = (number, places = 4) => `${number}`.padStart(places, '0').split('');

function Countdown(props) {
	const {number} = props;

	return (
		<div className="flip-clock-wrapper">
			{produceDigits(number).map((num, idx) => (
				<NumEl key={`cd-${idx}`} type={`countdown-${idx}`} current={Number(num)}/>
			))}
		</div>
	);
}

const CountdownContainer = styled.div`
  /*Width and Height*/

  & .flip-clock-wrapper ul {
	width: 60px;
	height: 90px;
  }

  /*Background colours*/

  & .flip-clock-wrapper .inn {
	color: #05e273;
	background-color: #0c0c91;
	font-size: 70px;
	font-family: Digi;

	font-size: 85px;
	font-weight: 500;
  }
`;

function CountdownComponent(props) {
	return (
		<CountdownContainer>
			<Countdown {...props} />
		</CountdownContainer>
	);
}

export default CountdownComponent;
