import React, { useEffect } from 'react';
import styled from 'styled-components';

import { fetchLjubjanaBusInformationForStation } from '../../actions';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';

const Arrivals = styled.span`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const Arrival = styled.div`
  margin-bottom: 5px;
  width: 50%;
`;

const Circle = styled.span`
  width: 20px;
  height: 20px;
  font-weight: 700;
  color: #0c0c91;
  background: #fff;
  border-radius: 50%;
  line-height: 18px;
  display: inline-block;
  text-align: center;
  padding-top: 3px;
  box-sizing: border-box;
  ${({ soon }) =>
    soon
      ? `
    background: #05e273;
    color: #0c0c91;
  `
      : null}
`;

const Direction = styled.span`
  font-weight: 700;
  font-size: 15px;
  margin-left: 2px;
  color: #e9f6f6;
  ${({ soon }) =>
    soon
      ? `
    color: #05e273;
  `
      : null}
`;
const MinutesContainer = styled.div`
  padding-left: 25px;
  margin-top: -4px;
`;

const Minute = styled.i`
  font-weight: 300;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  ${({ soon }) =>
    soon
      ? `
    font-weight: 700;
    font-size: 13px;
    color: #05e273;
    animation: blink 1s infinite;
    animation-timing-function: ease-in-out;
  `
      : null}
`;

const Column = styled.div`
  width: 50%;
`;

const StationTitle = styled.span`
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 6px;
	margin-top: -9px;
	display: block;
	text-align: center;<Paste>
`;

const Distance = styled.i`
  font-weight: 100;
  font-size: 15px;
  color: #e9f6f6;
`;

const mapDispatchToProps = dispatch => ({
  fetchBusInformationForStation: stationId => {
    dispatch(fetchLjubjanaBusInformationForStation(stationId));
  },
});

function mapStateToProps(state) {
  return {
    stations: state.bus,
  };
}

function StationInfo({ stationId, title, arrows, distance, fetchBusInformationForStation, stations }) {
  function generateStationData(stationId, stations) {
    let lines = stations && stations[stationId] ? stations[stationId] : [];
    return { lines: lines.map(generateLineData) };
  }

  function generateLineData(arrivals) {
    const line = {
      arrivals: uniqBy(arrivals, arrival => arrival.name + arrival.key + arrival.minutes),
    };

    if (line.arrivals.length) {
      line.number = line.arrivals[0].key;
      line.direction = getLineDirectionFromName(line.arrivals[0].name);
      line.departsSoon = Math.min(...line.arrivals.map(arrival => arrival.minutes)) <= 3;
    }

    return line;
  }

  function getLineDirectionFromName(name) {
    const splittedName = name ? name.split('-') : [];
    return splittedName[splittedName.length - 1] || '';
  }

  const fetchInfo = () => {
    fetchBusInformationForStation(stationId);
  };

  useEffect(() => {
    const REFRESH_LENGTH = 60;
    const interval = setInterval(fetchInfo, REFRESH_LENGTH * 1000);
    fetchInfo();
    return () => clearInterval(interval);
  }, [stationId]);

  const station = generateStationData(stationId, stations);

  return (
    <Column>
      <StationTitle>
        {arrows} {title} {arrows} <Distance>({distance}m)</Distance>
      </StationTitle>
      <Arrivals>
        {station.lines.map(line => (
          <Arrival key={line.direction + line.number}>
            <Circle soon={line.departsSoon}>{line.number}</Circle>
            &nbsp;
            <Direction soon={line.departsSoon}>{line.direction}</Direction>
            <MinutesContainer>
              {' '}
              in{' '}
              <span>
                {line.arrivals.map(arrival => (
                  <Minute key={arrival.minutes + arrival.name + arrival.key} soon={arrival.minutes <= 3}>
                    {arrival.minutes} min{' '}
                  </Minute>
                ))}
              </span>
            </MinutesContainer>
          </Arrival>
        ))}
      </Arrivals>
    </Column>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StationInfo);
