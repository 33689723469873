import React from 'react';
import styled from 'styled-components';

import Time from './Time.js';
import Weather from './Weather.js';

const InfoLeftContainer = styled.div`
  position: absolute;
  transform: translate3d(-120%, 0, 0);
  bottom: 2%;
  left: 2%;
  color: #0c0c91;

  font-size: 14px;
  z-index: 1;
  font-weight: 100;
  transition: opacity 0.25s ease-in-out;
  animation: sectionFlyIn 0.25s;
  animation-delay: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  &.animate-out {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    animation: sectionFlyOutLeft 0.45s;
    animation-delay: 0s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
`;

export default props => (
  <InfoLeftContainer className={props.leetOClock ? 'animate-out' : ''}>
    <Time />
    <Weather />
  </InfoLeftContainer>
);
