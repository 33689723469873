import React from 'react';
import { useManualQuery } from 'graphql-hooks';
import { useMqttMessages, messageActions } from './useMqttMessages';
import { useAuthentication } from './useAuthentication';

import { parseGraphQLApiErrors } from '../helpers/errorHelpers';

const Context = React.createContext(null);

export function BulletinsProvider({ children }) {
  const provider = useBulletinsProvider();

  return <Context.Provider value={provider}>{children}</Context.Provider>;
}

export function useBulletins() {
  return React.useContext(Context);
}

const actions = {
  addBulletin: 'addBuletin',
  updateBulletin: 'updateBuletin',
  deleteBulletin: 'deleteBuletin',
  fetchBulletins: 'fetchBulletins',
  fetchBulletinsSuccess: 'fetchBulletinsSuccess',
  fetchBulletinsErrors: 'fetchBulletinsErrors',
  updateActiveBulletins: 'updateActiveBulletins',
};

const initialState = {
  activeBulletins: [],
  bulletins: [],
  fetchInProgress: false,
  fetchErrors: [],
};

function filterActiveBulletins(bulletins = []) {
  const now = new Date().getTime();
  return bulletins.filter(
    bulletin => new Date(bulletin.start).getTime() <= now && new Date(bulletin.end).getTime() >= now
  );
}
function bulletinReducer(state, action) {
  switch (action.type) {
    case actions.fetchBulletins:
      return { ...state, fetchInProgress: true };
    case actions.fetchBulletinsSuccess:
      return { ...state, fetchInProgress: false, bulletins: action.bulletins };
    case actions.updateBulletin:
      // we can add a bulletin this way, by changing its date
      const { updatedBulletin = { id: '' } } = action;
      const itemIndex = state.bulletins.findIndex(bulletin => bulletin.id === updatedBulletin.id);
      const newBulletins = [...state.bulletins];

      if (itemIndex === -1) {
        newBulletins.push(updatedBulletin);
      } else {
        newBulletins[itemIndex] = updatedBulletin;
      }

      return {
        ...state,
        bulletins: newBulletins,
      };
    case actions.addBulletin:
      return {
        ...state,
        bulletins: [...state.bulletins, action.bulletin],
      };
    case actions.deleteBulletin:
      return {
        ...state,
        bulletins: state.bulletins.filter(bulletin => bulletin.id !== action.bulletinId),
      };
    case actions.updateActiveBulletins:
      return {
        ...state,
        activeBulletins: filterActiveBulletins(state.bulletins),
      };
    case actions.fetchBulletinsErrors:
      return { ...state, fetchInProgress: false, fetchErrors: action.errors };
    default:
      return state;
  }
}
const GET_BULLETINS = `
  query GetBulletinsForDevice($deviceId: String!) {
    bulletinsForDevice(deviceId: $deviceId) {
      upcoming {
        id
        headline
        content
        start
        end
        channels
      }
    }
  }
`;
export function useBulletinsProvider() {
  const { subscribeToMqttMessages } = useMqttMessages();
  const [state, dispatch] = React.useReducer(bulletinReducer, initialState);
  const { deviceId } = useAuthentication();

  const [fetchBulletinsQuery] = useManualQuery(GET_BULLETINS);

  React.useEffect(() => {
    async function fetchBulletins() {
      const { data, error } = await fetchBulletinsQuery({
        variables: { deviceId },
      });

      if (data && data.bulletinsForDevice && data.bulletinsForDevice.upcoming) {
        dispatch({ type: actions.fetchBulletinsSuccess, bulletins: data.bulletinsForDevice.upcoming });
      } else if (error) {
        const errors = parseGraphQLApiErrors(error);
        dispatch({ type: actions.fetchBulletinsErrors, errors });
      }
    }
    fetchBulletins();
  }, [deviceId]);

  React.useEffect(() => {
    const interval = setInterval(() => dispatch({ type: actions.updateActiveBulletins }), 10000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    // whenever we get new bulletins we set the updated ones too
    dispatch({ type: actions.updateActiveBulletins });
  }, [state.bulletins]);

  React.useEffect(() => {
    const unsubBulletinCreate = subscribeToMqttMessages(messageActions.BulletinAdded, bulletin => {
      dispatch({ type: actions.addBulletin, bulletin });
    });
    const unsubBulletinUpdate = subscribeToMqttMessages(messageActions.BulletinUpdated, updatedBulletin => {
      dispatch({ type: actions.updateBulletin, updatedBulletin });
    });
    const unsubBulletinDelete = subscribeToMqttMessages(messageActions.BulletinDeleted, bulletinId => {
      dispatch({ type: actions.deleteBulletin, bulletinId });
    });

    return () => {
      unsubBulletinCreate();
      unsubBulletinUpdate();
      unsubBulletinDelete();
    };
  }, []);

  return {
    activeBulletins: state.activeBulletins,
    fetchBulletinsInProgress: state.fetchInProgress,
    fetchBulletinsErrors: state.fetchBulletinsErrors,
  };
}
