import { useEffect } from 'react';

function useKeydown(callback, keys) {
  useEffect(() => {
    const handler = e => (keys.indexOf(e.keyCode) >= 0 ? callback(e.keyCode) : null);
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [keys]);
}

export default useKeydown;
