import React from 'react';
import moment from 'moment';
import { useManualQuery } from 'graphql-hooks';

import { useAuthentication } from './useAuthentication';
import { leetSeconds, leetTimeOfDay } from '../constants/timepoint';
import { parseGraphQLApiErrors } from '../helpers/errorHelpers';

const Context = React.createContext(null);
export function LeetVideoProvider({ children }) {
  const provider = useLeetVideoProvider();
  window['$leetVideoState'] = provider;

  return <Context.Provider value={provider}>{children}</Context.Provider>;
}
export function useLeetVideo() {
  return React.useContext(Context);
}

const GET_VIDEO = `
  query getVideo($id : String!) {
    device(id: $id) {
      scheduledLeetVideo {
        id,
        title,
      }
    }
  }
`;
const leetDelay = 10;
const actions = {
  error: 'error',
  video: 'video',
  timeout: 'timeout',
  playVideo: 'playVideo',
  clearVideo: 'clearVideo',
};
const reducer = (state, { payload, type }) => {
  switch (type) {
    case actions.video:
      return { ...state, video: payload };
    case actions.error:
      return { ...state, error: payload };
    case actions.timeout:
      return { ...state, timeout: payload };
    case actions.playVideo:
      return { ...state, isVideoPlaying: true };
    case actions.clearVideo:
      return { ...state, isVideoPlaying: false, video: null };
    default:
      return state;
  }
};
const initialState = {
  isVideoPlaying: false,
  timeout: null,
  video: null,
};

function useLeetVideoProvider() {
  const { deviceId } = useAuthentication();
  const [queryFn] = useManualQuery(GET_VIDEO, {
    variables: {
      id: deviceId,
    },
  });
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const loadData = async targetTime => {
    const { data, error } = await queryFn();

    if (error) {
      dispatch({ type: actions.error, payload: parseGraphQLApiErrors(error) });
    } else if (data && data.device && data.device.scheduledLeetVideo) {
      // Make sure its on time
      const timeDelta = Math.max(
        moment(targetTime)
          .subtract(leetSeconds, 'seconds')
          .diff(moment().milliseconds(0), 'milliseconds'),
        0
      );
      setTimeout(() => dispatch({ type: actions.video, payload: data.device.scheduledLeetVideo }), timeDelta);
    }

    setTimeoutLeet(targetTime.add(1, 'day'));
  };
  const setTimeoutLeet = (override = null) => {
    const [hours, minutes, seconds = 0] = leetTimeOfDay();
    let currentTime = moment().milliseconds(0);
    let targetTime = override
      ? moment(override)
      : moment().set({
          hours,
          minutes,
          seconds,
          milliseconds: 0,
        });

    // If current time is greater than the target time go to next day
    // We subtract leet seconds in case we are in the leetoclock countdown
    if (currentTime.isAfter(moment(targetTime))) {
      targetTime.add(1, 'days');
    }

    const timeDelta = Math.max(
      moment(targetTime)
        .subtract(leetDelay + leetSeconds, 'seconds')
        .diff(currentTime, 'milliseconds'),
      0
    );
    const leetTimeout = setTimeout(() => loadData(targetTime), timeDelta);

    dispatch({ type: actions.timeout, payload: leetTimeout });
  };

  React.useEffect(() => {
    setTimeoutLeet();

    return () => clearTimeout(state.timeout);
  }, []);

  // Call window['__setLeetTime__']([x, y]) to set leet time
  window['__setLeetTime__'] = time => {
    window['__leetTime__'] = time;
    setTimeoutLeet();
  };

  return {
    ...state,
    playVideo: () => dispatch({ type: actions.playVideo }),
    clearVideo: () => dispatch({ type: actions.clearVideo }),
  };
}
