import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Countdown from './countdown/Countdown';

const CountdownContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  text-align: center;
  backface-visibility: hidden;
`;
const Heading = styled.h1`
  color: #0c0c91;
  font-size: 1em;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

const KNOWABUNGA_DATE = new Date('2024-10-18');

export function KnowabungaCountdown({ date = KNOWABUNGA_DATE }) {
  return (
    <CountdownContainer>
      <Heading>Days left to Knowabunga 2024</Heading>
      <Countdown number={moment(date).diff(moment(), 'days')} />
    </CountdownContainer>
  );
}
