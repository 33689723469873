import React from 'react';
import axios from 'axios';
import { useManualQuery, useMutation } from 'graphql-hooks';

import { subscribeToPrivateTopics } from '../services/mqtt';
import { useMqttMessages, messageActions } from './useMqttMessages';

import config from '../config';
import { sleep } from '../helpers';
import { disconnectAndRefresh, parseGraphQLApiErrors } from '../helpers/errorHelpers';

const Context = React.createContext(null);

export function AuthenticationProvider({ children }) {
  const provider = useAuthenticationProvider();
  window['$authenticationState'] = provider;

  return <Context.Provider value={provider}>{children}</Context.Provider>;
}

export function useAuthentication() {
  return React.useContext(Context);
}
const actionTypes = {
  logoutDevice: 'logoutDevice',
  setAuthentication: 'setAuthentication',
  setError: 'setError',
  setLoading: 'setLoading',
  setDevice: 'setDevice',
};

const initialState = {
  loading: true, // since this is invoked on app mount, it actually is loading :D
  error: null,
  registerError: null,
  authentication: {
    device: {},
  },
};

function authenticationReducer(state, action) {
  switch (action.type) {
    case actionTypes.setAuthentication:
      return {
        ...state,
        loading: false,
        authentication: action.data,
      };
    case actionTypes.logoutDevice:
      return initialState;
    case actionTypes.setError:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.setDevice:
      return { ...state, authentication: { ...state.authentication, device: action.device } };
    case actionTypes.setLoading:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
}

const REGENERATE_ACTIVATION_CODE_MUTATION = `mutation RegenerateActivationCode($id: String!) {
  regenerateActivationCode(id: $id) {
    id
  }
}`;

const GET_DEVICE = `
  query GetDevice($deviceId: String!) {
    device(id: $deviceId) {
      city
      id
      twitterAccounts
      rssFeeds {
        url
        title
        imageUrl
      }
      name
      officeId
    }
  }
`;

export function useAuthenticationProvider() {
  const { subscribeToMqttMessages } = useMqttMessages();
  const [state, dispatch] = React.useReducer(authenticationReducer, initialState);
  const [fetchDeviceQuery] = useManualQuery(GET_DEVICE);
  const [regenerateActivationCodeMutation] = useMutation(REGENERATE_ACTIVATION_CODE_MUTATION);

  React.useEffect(() => {
    const unsubDeviceUpdate = subscribeToMqttMessages(messageActions.DeviceUpdated, device => {
      dispatch({ type: actionTypes.setDevice, device });
    });
    const unsubDeviceClear = subscribeToMqttMessages(messageActions.DeviceClear, () => {
      disconnectAndRefresh();
    });
    const unsubDeviceDelete = subscribeToMqttMessages(messageActions.DeviceDeleted, () => {
      disconnectAndRefresh();
    });

    return () => {
      unsubDeviceUpdate();
      unsubDeviceClear();
      unsubDeviceDelete();
    };
  }, []);

  React.useEffect(() => {
    if (state.authentication.device.id && state.authentication.device.officeId) {
      subscribeToPrivateTopics([
        `device/${state.authentication.device.id}`,
        `office/${state.authentication.device.officeId}`,
      ]);
    } else {
      subscribeToPrivateTopics([]);
    }
  }, [state.authentication.device.id, state.authentication.device.officeId]);

  async function logoutDevice() {
    const { error } = await regenerateActivationCodeMutation({
      variables: { id: state.authentication.device.id },
    });

    if (error) {
      dispatch({ type: actionTypes.setError, error: parseGraphQLApiErrors(error) });
    } else {
      disconnectAndRefresh();
    }
  }

  async function registerDevice(activationCode) {
    try {
      const authData = await axios.post(`${config.host}${config.basePath}/devices/register`, {
        activationCode,
      });
      console.log('Waiting 10s for AWS...');
      await sleep(10000); // so the creds apply in aws, don't ask i copied this

      if (authData.data) {
        const { device, ...rest } = authData.data;
        localStorage.setItem('authentication', JSON.stringify({ ...rest, deviceId: device.id }));
        // if all is well we invoke the fetch
        await fetchDeviceInfo();
      } else {
        console.error('invalid response received');
        window.location.reload();
      }
    } catch (err) {
      // we catch this on register device
      console.error('Error with activation', err);
      throw err;
    }
  }

  async function fetchDeviceInfo() {
    // if we have deviceId we fetch it, otherwise we need to register it
    const lsData = JSON.parse(localStorage.getItem('authentication'));
    if (lsData && lsData.userName && lsData.deviceId) {
      const { error, data } = await fetchDeviceQuery({ variables: { deviceId: lsData.deviceId } });

      if (error) {
        dispatch({ type: actionTypes.setError, error: parseGraphQLApiErrors(error) });
      } else if (data && data.device) {
        const { device } = data;
        dispatch({ type: actionTypes.setAuthentication, data: { ...lsData, device } });
        subscribeToPrivateTopics([`device/${device.id}`, `office/${device.officeId}`]);
      }
    } else {
      dispatch({ type: actionTypes.setLoading, loading: false });
    }
  }

  return {
    deviceId: state.authentication.device.id,
    fetchDeviceInfo,
    logoutDevice,
    registerDevice,
    state,
  };
}
