import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Loader from './Loader';

import Main from './Main';
import Register from './Register';

import { useAuthentication } from '../hooks/useAuthentication';
import { BulletinsProvider } from '../hooks/useBulletins';
import { DeviceShuffleProvider } from '../hooks/useDeviceShuffle';
import { LeetVideoProvider } from '../hooks/useLeetVideo';
import { WeatherProvider } from '../hooks/useWeather';

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
  body {
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #0c0c91;
    background-image: url('/_app_bck.jpg');
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
  }

  p {
    margin: 0;
  }

  * {
    outline: none;
  }

  body.transition:after {
    opacity: 1;
  }

  body:after {
    content: '';
    background: linear-gradient(to bottom, #0c0c91 1%,#05e273 140%);
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    will-change: opacity;
    opacity: 0;
    transition: opacity 1.2s ease-in-out;
  }

  @keyframes blink {
    0%,40%,100% {
      opacity: 1;
    }
    20% {
      opacity: 0.2;
    }
  }
  @keyframes sectionFlyIn {
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes sectionFlyOutLeft {
    to {
      transform: translate3d(-120%, 0, 0);
      opacity: 0;
    }
  }
  @keyframes sectionFlyOutRight {
    to {
      transform: translate3d(120%, 0, 0);
      opacity: 0;
    }
  }

  @keyframes hideVideoTitle{
    to {
      opacity: 0;
    }
  }

  @font-face {
    font-family: Digi;
    src: url(/digi.ttf);
  }

`;

const LoaderContainer = styled.div`
  margin: 40vh auto;
  width: 100px;
`;

function App() {
  const {
    fetchDeviceInfo,
    state: {
      authentication: { userName },
      loading,
    },
  } = useAuthentication();

  useEffect(() => {
    fetchDeviceInfo();
  }, []);

  return (
    <div className="App">
      <GlobalStyle />
      {loading ? (
        <LoaderContainer>
          <Loader size="100" />
        </LoaderContainer>
      ) : !!userName ? (
        <DeviceShuffleProvider>
          <BulletinsProvider>
            <WeatherProvider>
              <LeetVideoProvider>
                <Main />
              </LeetVideoProvider>
            </WeatherProvider>
          </BulletinsProvider>
        </DeviceShuffleProvider>
      ) : (
        <Register />
      )}
    </div>
  );
}

console.log(`Mappings for PC development:
- r - restart
- s - skipSong
- p - play/pause
- l - reload
- t - trola
- 1 - clock
- 2 - full screen logo
- 3 - events
- 4 - tweets
`);

export default App;
