import React, { useState, useEffect } from 'react';
import useKeydown from '../hooks/useKeydown';
import Logo from './Logo';
import CentralContainer from './CentralContainer';
import InfoLeft from './InfoLeft';
import Video from './Video';
import Slogan from './Slogan';
import Chats from './Chats';
import LeetOClockVideo from './LeetOClockVideo';
import { connectToMqtt } from '../services/mqtt';
import { setMode, updateFilteredChats } from '../actions';
import { connect } from 'react-redux';
import { useMqttMessages } from '../hooks/useMqttMessages';
import { useDeviceShuffle } from '../hooks/useDeviceShuffle';

const mapDispatchToProps = dispatch => ({
  setMode: mode => {
    dispatch(setMode(mode));
  },
  updateFilteredChats: () => {
    dispatch(updateFilteredChats());
  },
});

function Main(props) {
  const { processMqttMessage } = useMqttMessages();
  const { setIsShufflePlaybackPaused, setIsShufflePlaybackMuted } = useDeviceShuffle();
  const [isLeetVideoPlaying, setIsLeetVideoPlaying] = useState(false);

  useEffect(() => {
    connectToMqtt(processMqttMessage);
  }, []);

  useEffect(() => {
    setIsShufflePlaybackPaused(isLeetVideoPlaying);
    setIsShufflePlaybackMuted(isLeetVideoPlaying);
  }, [isLeetVideoPlaying]);

  useEffect(() => {
    const interval = setInterval(props.updateFilteredChats, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handler = e => {
      switch (e.keyCode) {
        // 48 "0"
        case 49: // "1"
          props.setMode('events');
          break;
        case 50: // "2"
          props.setMode('bus');
          break;
        case 51: // "3"
          props.setMode('logo');
          break;
        case 52: // "4"
          props.setMode('clock');
          break;
        case 53: // "5"
          props.setMode('visualizer');
          break;
        case 54: // "6"
          break;
        case 55: // "7"
          break;
        case 56: // "8"
          break;
        case 57: // "9"
          props.setMode('info');
          break;
        default:
          if (![76, 80, 82, 83, 227, 228, 176, 177, 179].includes(e.keyCode)) console.log('key not mapped', e.keyCode);
          break;
      }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, []);

  useKeydown(() => {
    window.location.reload();
  }, [76, 229]);

  return (
    <>
      <Video />
      <Logo leetOClock={isLeetVideoPlaying} />
      <CentralContainer />
      <InfoLeft leetOClock={isLeetVideoPlaying} />
      <Chats />
      <Slogan leetOClock={isLeetVideoPlaying} />
      <LeetOClockVideo setIsLeetVideoPlaying={setIsLeetVideoPlaying} />
    </>
  );
}

export default connect(null, mapDispatchToProps)(Main);
